export const addMinutesToDate = (date, mnts) => {
  return new Date(date.setTime(date.getTime() + mnts * 60 * 1000));
};
export const roundOffTimeToFive = (date) => {
  let minutes = date.getMinutes();
  minutes = minutes + (5 - (minutes % 5));
  return new Date(date.setMinutes(minutes));
};

const getHHfn = (payload) => {
  payload %= 12;
  payload = payload === 0 ? 12 : payload;
  payload = payload < 10 ? `0${payload}` : `${payload}`;
  return payload;
};

export default {
  install: (app, options) => {
    // inject a globally available $translate() method
    app.config.globalProperties.$dateUtilities = {
      addMinutesToDate: (dateProp, mnts) => {
        const date = dateProp;
        return new Date(date.setTime(date.getTime() + mnts * 60 * 1000));
      },
      roundOffTimeToFive: (dateProp) => {
        roundOffTimeToFive(dateProp);
      },
      getHH: getHHfn,
      getTimeAsHHMM: (payload) => {
        const dateObj = new Date(payload);
        const objHHMM = { hour: "", minute: "", ampm: "" };

        objHHMM.hour = dateObj.getHours();
        objHHMM.minute = dateObj.getMinutes();

        objHHMM.ampm = objHHMM.hour < 12 ? "AM" : "PM";

        objHHMM.hour = getHHfn(objHHMM.hour);

        objHHMM.minute = objHHMM.minute < 10 ? `0${objHHMM.minute}` : `${objHHMM.minute}`;

        return objHHMM;
      }
    };
  }
};
