<script>
import { IconsUsed } from "@/assets/constants/IconsUsed";
import { PROFILE_NAV, ADMIN_NAV, CANDIDATE_NAV, EMPLOYER_NAV, HR_TEAM_NAV } from "@/assets/constants/NavigationMenu";
import NotificationIcon from "@/notification/components/NotificationIcon.vue";

export default {
  name: "NavbarNew",
  components: { NotificationIcon },
  data() {
    return {
      IconsUsed,
      PROFILE_NAV,
      ADMIN_NAV,
      CANDIDATE_NAV,
      EMPLOYER_NAV,
      HR_TEAM_NAV,
      theme_choices: [
        { name: "light", icon: IconsUsed.SUN, classes: ["text-orange-500"] },
        { name: "dark", icon: IconsUsed.MOON, classes: ["text-sky-400"] },
       // { name: "system", icon: IconsUsed.TERMINAL, classes: ["text-slate-200"] }
      ],
      isMouseOver: false,
      mouseOverIndex: null,
      currentThemeIdx: 0,
      dark_mode: true
    };
  },
  computed: {
    navigation_menu() {
      const user_type = this.$store.getters["LoginStore/user_type"];
      if (!user_type) return [...this.PROFILE_NAV];

      const user_nav_menu = (user_type + "_nav").toUpperCase();
      return [...this[user_nav_menu], ...this.PROFILE_NAV];
    },
    user_permissions() {
      return this.$store.getters["LoginStore/user_permissions"];
    },
    nextThemeIdx() {
      if (this.currentThemeIdx + 1 < this.theme_choices.length) return this.currentThemeIdx + 1;
      else return 0;
    },
    current_theme_in_store() {
      return this.$store.getters["AppModule/current_theme"];
    }
  },
  methods: {
    isMenuItemAllowed(_name) {
      const user_type = this.$store.getters["LoginStore/user_type"];
      const matchedRoute = this.$router.getRoutes().find((each_route) => each_route.name === _name);
      const route_meta = matchedRoute?.meta;

      if (!route_meta || !Object.hasOwn(route_meta, "allowed_user_types")) return true;
      return route_meta.allowed_user_types.includes(user_type) || route_meta.allowed_user_types.includes("all");
    },
    toggle_dropdown(e) {
      const target = e.target;
      const target_idx = target.getAttribute("data-index");
      this.isMouseOver = !this.isMouseOver;

      /*console.log("Toggle Dropdown : " + this.isMouseOver);
      console.log("Index : " + this.mouseOverIndex);*/

      if (!this.isMouseOver)
        setTimeout(() => {
          //If the Mouse is not over current target, hide dropdown
          if (!this.isMouseOver || target_idx !== this.mouseOverIndex) target.removeAttribute("open");
        }, 200);
      else {
        this.mouseOverIndex = target_idx;
        target.setAttribute("open", true);
      }
    },
    cycleTheme() {
      const noOfThemes = this.theme_choices.length;
      this.currentThemeIdx + 1 < noOfThemes ? this.currentThemeIdx++ : (this.currentThemeIdx = 0);
    },
    handleDynamicFunctionCall(_name, params) {
      this[_name](params);
    },
    logout() {
      this.$store.dispatch("LoginStore/logout");
    },
    is_item_permitted(_menu_item) {
      if (!Object.hasOwn(_menu_item, "permission_required")) return true;

      return this.$projectUtilities.resolveObject(_menu_item.permission_required, this.user_permissions);
    },
    handleOutsideClick(event) {
      const mobileDropdown = this.$refs.mobileDropdown;
      if (mobileDropdown && !mobileDropdown.contains(event.target)) {
        mobileDropdown.removeAttribute('open');
      }
    },
    closeMobileDropdown() {
      const mobileDropdown = this.$refs.mobileDropdown;
      if (mobileDropdown) {
        mobileDropdown.removeAttribute('open');
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    this.$router.afterEach(() => {
      this.closeMobileDropdown();
    });

    const current_theme = this.$store.getters["AppModule/current_theme"];
    const preferred_theme = this.$store.getters["AppModule/preferred_theme"];

    this.currentThemeIdx = this.theme_choices.findIndex((e) => e.name === current_theme);
    if (!this.currentThemeIdx || this.currentThemeIdx === -1)
      this.currentThemeIdx = this.theme_choices.findIndex((e) => e.name === preferred_theme);
    if (!this.currentThemeIdx || this.currentThemeIdx === -1)
      //this.currentThemeIdx = this.theme_choices.findIndex((e) => e.name === "system");
      this.currentThemeIdx = this.theme_choices.findIndex((e) => e.name === "light");
  },
  watch: {
    currentThemeIdx() {
      this.$store.commit("AppModule/set_theme", this.theme_choices[this.currentThemeIdx].name);
    },
    current_theme_in_store() {
      this.currentThemeIdx = this.theme_choices.findIndex((e) => e.name === this.current_theme_in_store);
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }
};
</script>

<template>
  <div class="navbar bg-base-100 shadow-md sticky top-0 z-[1000]" id="navbar">
    <!--  Sidebar Button   -->
    <div class="flex-none hidden" data-hidden-until-further-use>
      <button class="btn btn-square btn-ghost">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-5 h-5 stroke-current">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
    </div>
    <!--  Sidebar Button End   -->

    <!--  Logo   -->
    <div class="flex-1 flex-row">
      <RouterLink to="/" class="btn btn-ghost">
        <img src="@/assets/logo/priohire_logo_blue.png" alt="logo" class="h-full" />
      </RouterLink>
    </div>
    <!--  Logo END  -->

    <!-- Desktop Navigation -->
    <div class="flex-none hidden md:flex">
      <ul class="menu md:menu-horizontal rounded items-center gap-1.5">
        <template v-for="(menu_item, idx) in navigation_menu">
          <!--  This block is for menu items that need to be displayed in a group        -->
          <li
            v-if="menu_item.children && !menu_item.spread_children && isMenuItemAllowed(menu_item.name) && is_item_permitted(menu_item)"
            :key="'a' + idx"
          >
            <details class="group" :data-index="idx" @mouseenter="toggle_dropdown" @mouseleave="toggle_dropdown">
              <summary
                class="peer para-sm hover:bg-primary-content hover:text-primary focus:bg-primary-content focus:text-primary duration-500"
              >
                {{ menu_item.title }}
              </summary>
              <ul class="p-2 bg-base-100 relative z-50 rounded-lg rounded-t-none !mt-0">
                <template v-for="(menu_item_child, child_idx) in menu_item.children">
                  <li :key="child_idx" v-if="isMenuItemAllowed(menu_item_child.name) && is_item_permitted(menu_item_child)">
                    <RouterLink
                      v-if="menu_item_child.link"
                      :to="menu_item_child.link"
                      class="group duration-500 hover:bg-primary-content hover:text-primary focus:bg-primary-content focus:text-primary para-sm"
                      active-class="activeLink"
                    >
                      {{ menu_item_child.title }}
                    </RouterLink>
                    <button
                      v-else-if="menu_item_child.type === 'button'"
                      @click="handleDynamicFunctionCall(menu_item_child.function)"
                      class="para-sm"
                      :class="menu_item_child?.classes"
                    >
                      {{ menu_item_child.title }}
                    </button>
                  </li>
                </template>
              </ul>
            </details>
          </li>
          <!--  This block is for menu items that need to be displayed separately        -->
          <template v-else-if="menu_item.spread_children">
            <template v-for="(menu_item_child, child_idx) in menu_item.children">
              <li v-if="is_item_permitted(menu_item_child) && isMenuItemAllowed(menu_item_child.name)" :key="child_idx" class="">
                <RouterLink
                  :to="menu_item_child.link"
                  class="group duration-500 hover:bg-primary-content hover:text-primary focus:bg-primary-content focus:text-primary"
                  active-class="activeLink"
                >
                  <summary class="para-sm">
                    {{ menu_item_child.title }}
                  </summary>
                </RouterLink>
              </li>
            </template>
          </template>
          <li v-else-if="is_item_permitted(menu_item) && isMenuItemAllowed(menu_item.name)" :key="'c' + idx">
            <RouterLink :to="menu_item.link" class="para-sm">{{ menu_item.title }}</RouterLink>
          </li>
        </template>
      </ul>
    </div>
    <!-- Desktop Navigation END -->

    <!--  Dark Mode Switch  -->
    <!--    <label class="swap swap-rotate">
      <input type="checkbox" class="theme-controller" value="dark" v-model="dark_mode" />
      &lt;!&ndash; sun icon &ndash;&gt;
      <v-icon :icon="IconsUsed.SUN" class="swap-off text-orange-500 text-lg" />
      &lt;!&ndash; moon icon &ndash;&gt;
      <v-icon :icon="IconsUsed.MOON" class="swap-on text-sky-400 text-lg" />
    </label>-->
    <!--  Dark Mode Switch END -->

    <NotificationIcon />

    <Transition>
      <div
        class="cursor-pointer tooltip tooltip-left"
        :data-tip="`Switch To: ${theme_choices[nextThemeIdx].name}`"
        @click="cycleTheme"
        :key="theme_choices[currentThemeIdx].name"
      >
        <v-icon
          :icon="theme_choices[currentThemeIdx].icon"
          class="text-lg cursor-pointer"
          :class="theme_choices[currentThemeIdx].classes"
        />
      </div>
    </Transition>

    <!--   Mobile Navigation -->
    <details class="dropdown dropdown-end md:hidden" ref="mobileDropdown">
      <summary tabindex="0" class="btn btn-ghost md:hidden">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
        </svg>
      </summary>
      <ul tabindex="0" class="menu menu-lg dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-[85vw]">
        <template v-for="(menu_item, idx) in navigation_menu">
          <li v-if="menu_item.children && !menu_item.spread_children" :key="'a' + idx" class="para-lg">
            <details class="group" :data-index="idx" @mouseenter="toggle_dropdown" @mouseleave="toggle_dropdown">
              <summary class="peer para-lg">
                {{ menu_item.title }}
              </summary>
              <ul class="p-2 bg-base-100 relative z-50 rounded-lg rounded-t-none !mt-0">
                <li v-for="(menu_item_child, child_idx) in menu_item.children" :key="child_idx">
                  <RouterLink
                    v-if="menu_item_child.link"
                    :to="menu_item_child.link"
                    class="text-right group duration-500 hover:bg-primary-content hover:text-primary focus:bg-primary-content focus:text-primary para-lg"
                    active-class="activeLink"
                  >
                    {{ menu_item_child.title }}
                  </RouterLink>
                  <button
                    v-else-if="menu_item_child.type === 'button'"
                    @click="handleDynamicFunctionCall(menu_item_child.function)"
                    class="para-lg"
                    :class="menu_item_child?.classes"
                  >
                    {{ menu_item_child.title }}
                  </button>
                </li>
              </ul>
            </details>
          </li>
          <template v-else-if="menu_item.spread_children">
            <li v-for="(menu_item_child, child_idx) in menu_item.children" :key="child_idx" class="">
              <RouterLink
                :to="menu_item_child.link"
                class="group para-lg text-right group duration-500 hover:bg-primary-content hover:text-primary focus:bg-primary-content focus:text-primary"
                active-class="activeLink"
              >
                {{ menu_item_child.title }}
              </RouterLink>
            </li>
          </template>
          <li v-else :key="'c' + idx">
            <RouterLink :to="menu_item.link" class="para-lg">{{ menu_item.title }}</RouterLink>
          </li>
        </template>
      </ul>
    </details>
    <!--   Mobile Navigation END -->
  </div>
</template>

<style scoped>
.activeLink {
  @apply text-primary hover:bg-base-100 focus:bg-base-100 focus:text-primary cursor-default;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
