import "@/assets/main.css";

import { createApp } from "vue";
import App from "@/entrypoints/App.vue";

import router from "@/global-router";
import store from "@/global-store.index";
import dateAndTimeHelpers from "@/plugins/plugins.date-and-time-utilities";
import projectUtilities from "@/plugins/plugins.project-utilities";

import { Icon } from "@iconify/vue";
import AWN from "awesome-notifications";

import logger from "@/plugins/plugins.logger";

import ActionCableVue from "actioncable-vue";
const actionCableVueOptions = {
  debug: false,
  debugLevel: "debug",
  connectImmediately: true,
  store
};

const homeNodeSelector = "#home";
const homeNode = document.querySelector(homeNodeSelector);

router.afterEach((to, from) => {
  //document.getElementById("appBody").scrollTo({ top: 0, left: 0 });
});

if (!homeNode) {
  console.log("Document Not Found!");
} else {
  homeNode.setAttribute("data-theme", "light");

  const app = createApp(App);

  app.use(dateAndTimeHelpers);
  app.use(projectUtilities);
  
  app.use(store);
  app.use(router);

  app.use(ActionCableVue, actionCableVueOptions);
  app.use(logger);

  app.config.globalProperties.$awn = new AWN({
    position: "bottom-right",
    minDurations: { "async-block": 100 },
    maxNotifications: 5
  });

  app.component("v-icon", Icon);

  app.mount(homeNodeSelector);
}
