<script>
import { RouterView } from "vue-router";

import NavbarNew from "@/components/navigation/NavbarNew.vue";

export default {
  components: {
    NavbarNew,
    RouterView
  },
  data() {
    return {
      isLoginModalOpen: false,
      isMenuSidebarOpen: false,
      browserId: null,
      defaultHidden: false
    };
  },
  methods: {
    toggleLoginModal() {
      this.isLoginModalOpen = !this.isLoginModalOpen;
      this.$projectUtilities.noScrollAppBody({
        scroll: !this.isLoginModalOpen
      });
    },
    toggleMenuSidebar(isOpen) {
      this.isMenuSidebarOpen = isOpen;
      this.$projectUtilities.noScrollAppBody({ scroll: !isOpen });
    }
  },
  computed: {
    menuSidebarClasses() {
      const classesToApply = {
        absolute: true,
        "-left-full": true
      };

      if (this.defaultHidden && this.isMenuSidebarOpen) {
        //console.log("Hidden By Default & Open");
        classesToApply["-left-full"] = false;
        classesToApply["left-0"] = true;
      }
      if (this.defaultHidden && !this.isMenuSidebarOpen) {
        //console.log("Hidden By Default & Close");
      }
      if (!this.defaultHidden && this.isMenuSidebarOpen) {
        //console.log("Shown by Default & Open");
        classesToApply["md:[position:initial]"] = true;
        classesToApply["-left-full"] = false;
        classesToApply["left-0"] = true;
      }
      if (!this.defaultHidden && !this.isMenuSidebarOpen) {
        //console.log("Shown by Default & Close");
        classesToApply["md:[position:initial]"] = true;
        classesToApply["-left-full"] = true;
        classesToApply["left-0"] = false;
      }
      return classesToApply;
    }
  },
  mounted() {
    this.browserId = this.$projectUtilities.getBrowserID();
    const base_url = window.location.protocol + "//" + window.location.host;
    this.$store.commit("LoginStore/setState", { key: "base_url", value: base_url });
  },
  beforeCreate() {
    const base_url = window.location.protocol + "//" + window.location.host;
    this.$store.commit("LoginStore/setState", { key: "base_url", value: base_url });
    this.$store.dispatch("LoginStore/fetchDetails", {
      query: { ...this.$route.query }
    });

    let color_mode_to_set = localStorage.getItem("color-mode");
    if (!color_mode_to_set) color_mode_to_set = this.$store.getters["AppModule/preferred_theme"];

    this.$store.commit("AppModule/set_theme", color_mode_to_set);
  }
};
</script>

<template>
  <div class="min-h-screen flex flex-col items-center" id="vue_body">
    <NavbarNew />
    <router-view class="w-full"></router-view>
  </div>
</template>
