import store from "../global-store.index";
import { DirectUpload } from "activestorage";
import moment from "moment";

const _resolveObject = (path, obj = self, separator = ".") => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev?.[curr], obj);
};

export const areObjectsEqual = (obj1, obj2) => {
  const keysToIgnore = ["created_at", "updated_at"];
  if (!obj1 && !obj2) return true;
  if (!obj1 || !obj2) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      if (!areObjectsEqual(obj1[key], obj2[key])) return false;
    } else if (!keysToIgnore.includes(key) && obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

const flattenObject = (ob) => {
  const toReturn = {};

  for (const i in ob) {
    if (!Object.hasOwn(ob, i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!Object.hasOwn(flatObject, x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
export default {
  install: (app, options) => {
    // inject a globally available $noScrollAppBody() method
    app.config.globalProperties.$projectUtilities = {
      ratingToStars: (rating, total = 5) => {
        let floatValue = parseFloat(rating);

        if (floatValue > total) floatValue = floatValue % total;

        const whole = Math.floor(floatValue);
        const half = floatValue.toString().indexOf(".") !== -1;
        let empty = 0;

        if (half) empty = total - 1 - whole;
        else empty = total - whole;

        return {
          whole,
          half,
          empty
        };
      },
      noScrollAppBody: (scroll) => {
        const appBody = document.getElementById("appBody");
        if (!scroll) appBody.style["overflow-y"] = "hidden";
        else appBody.style["overflow-y"] = "auto";
      },
      generateIconName: (name) => {
        return name.replace(/([a-z0–9])([A-Z])/g, "$1-$2").toLowerCase();
      },
      randomImageLink: ({ type = "name", placeholder_settings, size = "250" }) => {
        if (type === "name") {
          const default_name_settings = {
            full_name: "John Doe",
            background: "0D8ABC",
            text_color: "fff",
            length: 2
          };
          if (!placeholder_settings) placeholder_settings = default_name_settings;
          else
            placeholder_settings = {
              ...default_name_settings,
              ...placeholder_settings
            };

          return `https://ui-avatars.com/api/?background=${placeholder_settings.background}&color=${placeholder_settings.text_color}&length=${placeholder_settings.length}&size=${size}&name=${placeholder_settings?.full_name?.replaceAll(" ", "+")}`;
        }
        const default_image_settings = { gender: "male" };
        if (!placeholder_settings) placeholder_settings = default_image_settings;
        else
          placeholder_settings = {
            ...default_image_settings,
            ...placeholder_settings
          };

        const rand = Math.floor(Math.random() * 1000000);
        return `https://xsgames.co/randomusers/avatar.php?g=${placeholder_settings.gender}&dummy=${rand}`;
      },
      getBrowserID: () => {
        if (store.getters["AppModule/browserId"]) return store.getters["AppModule/browserId"];

        store.commit("AppModule/create");
        return store.getters["AppModule/browserId"];
      },
      uploadFile: (file, progress) => {
        const url = document.querySelector("meta[name=upload-url]").getAttribute("content");
        const upload = new DirectUpload(file, url, {
          directUploadWillStoreFileWithXHR: (request) => {
            request.upload.addEventListener("progress", (event) => {
              progress && progress(parseFloat((100 * (event.loaded / (event.total || event.totalSize))).toFixed(2)));
            });
          }
        });
        return new Promise((resolve) => {
          upload.create((error, blob) => {
            resolve({
              error,
              blob
            });
          });
        });
      },
      fileReader: (file) =>
        new Promise((resolve) => {
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            resolve(null);
          }
        }),
      initFormData: (dataSet, fieldSet, fieldTypes) => {
        if (dataSet === {}) return dataSet;
        const tempFormData = JSON.parse(JSON.stringify(dataSet));

        let formData = {};

        for (const field_idx in fieldSet) {
          const field_id = fieldSet[field_idx].id;

          if (fieldSet[field_idx].props.type === fieldTypes.ARRAY) {
            formData[field_id] = tempFormData[field_id];
            delete tempFormData[field_id];
          } else if (fieldSet[field_idx].props.type === fieldTypes.GROUP) {
            formData[field_id] = {};
            const group_children_fields = fieldSet[field_idx].children;

            for (const group_children_idx in group_children_fields) {
              const group_children_id = group_children_fields[group_children_idx].id;
              formData[field_id][group_children_id] = tempFormData[group_children_id] ? tempFormData[group_children_id] : "";

              if (group_children_fields[group_children_idx].props.type === fieldTypes.MULTI_INPUT) {
                formData[field_id][group_children_id] = {};
                const multi_inputs = group_children_fields[group_children_idx].props.inputs;
                for (let multi_input_idx = 0; multi_input_idx < multi_inputs.length; multi_input_idx++) {
                  const each_multi_input_id = multi_inputs[multi_input_idx].id;
                  formData[field_id][group_children_id][each_multi_input_id] = tempFormData[each_multi_input_id]
                    ? tempFormData[each_multi_input_id]
                    : null;
                }
              }

              delete tempFormData[group_children_id];
            }
          }
        }
        formData = { ...tempFormData, ...formData };
        return formData;
      },

      numToWords: (num) => {
        if (!num) return "Zero";
        const a = [
          "",
          "one ",
          "two ",
          "three ",
          "four ",
          "five ",
          "six ",
          "seven ",
          "eight ",
          "nine ",
          "ten ",
          "eleven ",
          "twelve ",
          "thirteen ",
          "fourteen ",
          "fifteen ",
          "sixteen ",
          "seventeen ",
          "eighteen ",
          "nineteen "
        ];
        const b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

        if ((num = num.toString()).length > 9) return "overflow";

        const n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

        if (!n) return;
        let str = "";

        str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " : "";
        str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " : "";
        str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " : "";
        str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " : "";
        str += n[5] != 0 ? (str !== "" ? "and " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) : "";

        return str;
      },
      /* Returns Value of nested property within an object
       * e.g => resolveObject('some.nested.property', objectToLookInto); */
      resolveObject: (path, obj = self, separator = ".") => {
        const properties = Array.isArray(path) ? path : path.split(separator);
        return properties.reduce((prev, curr) => prev?.[curr], obj);
      },
      updateNestedObject: (obj = self, path, value, separator = ".") => {
        let schema = obj; // a moving reference to internal objects within obj
        const pList = Array.isArray(path) ? path : path.split(separator);
        const len = pList.length;
        for (let i = 0; i < len - 1; i++) {
          const elem = pList[i];
          if (!schema[elem]) schema[elem] = {};
          schema = schema[elem];
        }

        schema[pList[len - 1]] = value;

        return schema;
      },
      getCalendarTime: (_time, showTime = false) => {
        return moment(_time).calendar({
          sameDay: function (now) {
            if (this.isBefore(now))
              return `[${this.fromNow()}${showTime ? ", " + this.format("h:mm A") : ""}]`; //Put all text that is not format specifiers inside [] only
            else return `[In ${this.toNow(true)}${showTime ? ", " + this.format("h:mm A") : ""}] `;
          },
          nextDay: function () {
            return `[Tomorrow${showTime ? ", " + this.format("h:mm A") : ""}]`;
          },
          lastDay: function () {
            return `[${this.toNow()}${showTime ? ", " + this.format("h:mm A") : ""}]`;
          },
          nextWeek: function () {
            return `[This] dddd[${showTime ? ", " + this.format("h:mm A") : ""}]`;
          },
          lastWeek: function () {
            return `dddd[${showTime ? ", " + this.format("h:mm A") : ""}]`;
          },
          sameElse: function () {
            return `Do MMM, 'YY[${showTime ? ", " + this.format("h:mm A") : ""}]`;
          }
        });
      },
      resolveEnablingDependency(dependent_on_object = null, dependency_paths = null, dynamic_value_object = null) {
        if (!dependent_on_object || !dependency_paths) return false;
        for (const dependency_path of dependency_paths) {
          const dynamic_match = dependency_path.match(/\{\{[^}]*\}\}/gm);

          if (!dynamic_match) {
            if (!_resolveObject(dependency_path, dependent_on_object)) return false;
          } else {
            const _key = dynamic_match[0].replaceAll("{", "").replaceAll("}", "");
            let arr_index=""
            let dynamic_value=""
            if(_key.match(/\[[^\]]*\]/gm))
            {
              arr_index =
                _key.match(/\[[^\]]*\]/gm).length > 0
                  ? _key
                    .match(/\[[^\]]*\]/gm)[0]
                    .replaceAll("[", "")
                    .replaceAll("]", "")
                  : null;

              const dynamic_key = arr_index ? _key.split("[")[0] : _key;

              dynamic_value = _resolveObject(dynamic_key, dynamic_value_object);
              if (arr_index) dynamic_value = dynamic_value[arr_index];
            }
            else
            {
              dynamic_value=_resolveObject(_key, dynamic_value_object);
            }
            const final_dependency_path = dependency_path.replace(/\{\{[^}]*\}\}/gm, dynamic_value);

            if (!_resolveObject(final_dependency_path, dependent_on_object)) return false;
          }
        }
        return true;
      },
      flattenObject,
      areObjectsEqual
    };
  }
};
