<script>
import { IconsUsed } from "@/assets/constants/IconsUsed";
import { WSChannels } from "@/assets/constants/Channels";
import NotificationsModule, {
  NOTIFICATIONS_MODULE_NAME,
} from "@/notification/notification.vuex";

export default {
  name: "NotificationIcon",
  mixins: [],
  data() {
    return { IconsUsed, is_connected: false };
  },
  channels: {
    [NOTIFICATIONS_MODULE_NAME]: {
      async connected() {
        // console.log("This : ", JSON.parse(JSON.stringify(this)));
        try {
          this.notifications_channel_connected(true);
        } catch (e) {
          this.$store.commit(
            "NotificationsModule/notifications_channel_connected",
            true
          );
        }
        this.$logger.debug(NOTIFICATIONS_MODULE_NAME, "connected");
      },
      disconnected() {
        this.$logger.debug(NOTIFICATIONS_MODULE_NAME, "disconnected");
        try {
          this.notifications_channel_connected(false);
        } catch (e) {
          this.$store.commit(
            "NotificationsModule/notifications_channel_connected",
            false
          );
        }
      },
      rejected() {
        this.$logger.debug(NOTIFICATIONS_MODULE_NAME, "rejected");
        try {
          this.notifications_channel_connected(false);
        } catch (e) {
          this.$store.commit(
            "NotificationsModule/notifications_channel_connected",
            false
          );
        }
      },
      received({ event, body, params }) {
        this.$logger.debug(
          NOTIFICATIONS_MODULE_NAME,
          "received",
          event,
          body,
          params
        );
        switch (event) {
          case "notification":
            this.$awn[body.type](body.message);
            break;
          case "validation_error":
            console.error(body);
            break;
          default:
            // This will call respected `action` or `mutation`
            if (
              Object.keys(NotificationsModule.mutations).indexOf(event) > -1
            ) {
              this.$store.commit(`${NOTIFICATIONS_MODULE_NAME}/${event}`, {
                body,
                params,
              });
            } else if (
              Object.keys(NotificationsModule.actions).indexOf(event) > -1
            ) {
              this.$store.dispatch(`${NOTIFICATIONS_MODULE_NAME}/${event}`, {
                body,
                params,
              });
            }
            break;
        }
      },
    },
  },
  computed: {
    is_notifications_channel_connected() {
      return this.$store.getters[
        "NotificationsModule/is_notifications_channel_connected"
      ];
    },
    notifications() {
      return this.$store.getters["NotificationsModule/notifications"];
    },
    notifications_metadata() {
      return this.$store.getters["NotificationsModule/notifications_metadata"];
    },
  },

  beforeMount() {
    this.$cable.subscribe(
      {
        channel: WSChannels.NotificationChannel,
        browser_id: this.$projectUtilities.getBrowserID(),
      },
      NOTIFICATIONS_MODULE_NAME
    );
  },
  beforeUnmount() {
    this.connected(false);
    this.$cable.unsubscribe(NOTIFICATIONS_MODULE_NAME);
  },
  methods: {
    perform(action, data) {
      console.log(NOTIFICATIONS_MODULE_NAME, action);
      this.$logger.debug(NOTIFICATIONS_MODULE_NAME, "perform", action, data);
      this.$cable.perform({
        channel: NOTIFICATIONS_MODULE_NAME,
        action,
        data,
      });
    },
    onConnectionChanged(is_connected) {
      console.log(is_connected);
      if (is_connected) {
        // this.perform("all_notifications");
        this.perform("workspace_notification");
      }
    },
    notifications_channel_connected(flag) {
      this.$store.commit(
        "NotificationsModule/notifications_channel_connected",
        flag
      );
    },
    // all_notifications(data) {
    //   console.log("Data : ", data);
    //   this.$store.commit("NotificationsModule/all_notifications", data);
    // },
    workspace_notification(data) {
      console.log("Data : ", data);
      this.$store.commit("NotificationsModule/workspace_notification", data);
    },
    bellIconClicked() {
      this.$router.push("/notifications");
      this.perform("workspace_notifications_mark_as_seen");
    },
  },
  watch: {
    is_notifications_channel_connected(flag) {
      this.onConnectionChanged(flag);
    },
  },
};
</script>

<template>
  <div class="indicator mr-4 cursor-pointer" @click="bellIconClicked">
    <span
      class="indicator-item badge badge-secondary para-xs p-1"
      v-show="notifications_metadata?.unseen_count > 0"
    >
      {{ notifications_metadata?.unseen_count || 0 }}
    </span>
    <v-icon :icon="IconsUsed.BELL" class="para-lg" />
  </div>
</template>

<style scoped></style>
